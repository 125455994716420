@use "./colors.scss";

img {
  user-select: none !important;
}

.img-w-100 {
  width: 100%;
}

.banner-background {
  height: 747px;
  background-image: linear-gradient(
      82.64deg,
      rgba(247, 126, 11, 0.26) 0%,
      rgba(247, 126, 11, 0.00533414) 54.73%,
      rgba(247, 126, 11, 0) 75.28%
    ),
    url(../images/BannerIMage.png);
  position: relative;
  // background-size: contain;
  background-blend-mode: multiply;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.click_away_heading {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 25px;
  text-transform: uppercase;
  color: colors.$grey;
  line-height: 2;

  u {
    border-bottom: 3px solid colors.$black;
  }
}

.click_away_sub_heading {
  font-weight: 300;
  font-size: 20px;
  color: colors.$grey;
  font-family: Poppins, sans-serif;
}

.we_know_heading {
  font-weight: 900;
  font-size: 36px;
  text-align: center;
  color: #e0e0e0;
}

.we_know_sub_heading {
  font-family: Poppins, sans-serif;

  font-weight: 500;
  font-size: 16px;
  line-height: 23.6px;
  color: #828282;
  border-left: 8px solid colors.$orange;
  padding-left: 1rem;
}

.help_heading {
  font-family: Poppins, sans-serif;

  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: colors.$grey;
  margin: 0px;
}

.help_sub_heading {
  font-family: Poppins, sans-serif;

  text-align: center;
  font-weight: 300;
  font-size: 18px;
  color: colors.$grey;
}

.service_title {
  font-family: Poppins, sans-serif;

  font-weight: 700;
  font-size: 17px;
  text-align: center;
  color: colors.$white;
  margin-left: 8px;
}

.next-img {
  height: 40px;
  width: 40px;
  margin: 0rem 1rem;
}

.back-img {
  height: 20px;
  width: 20px;
  margin: 0rem 0rem;
}

// ------------ Footer Section -------------- //

.footer_headings {
  font-family: Poppins, sans-serif;

  font-weight: 500;
  color: colors.$footer_grey;
  font-size: 18px;
  margin-top: 10px;
}

.footer_sub_headings {
  font-weight: 400;
  color: colors.$light_grey;
  font-size: 16px;
}

@media (max-width: 900px) {
  .mobile-w-100 {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .we-know {
    display: none;
  }
}

@media (min-width: 1400px) {
  .container-sm {
    max-width: 838px !important;
  }
}

@media (min-width: 767px) {
  .mobile-content {
    display: none;
  }
}

@media (max-width: 767px) {
  .hide-logo {
    display: none;
  }
}

.phonenumber_field input {
  border: none !important;
  width: 100% !important;
}
.view .ant-form-item {
  margin-bottom: 0px !important;
}
.ant-checkbox-inner {
  border-color: #f77e0b !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f77e0b !important;
  border-color: #f77e0b !important;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white !important;
}
.ant-checkbox-checked:after {
  border: none !important;
}
.ant-radio-inner {
  // border-color: #F77E0B !important;
  border: 2px solid #f77e0b !important;
}
.ant-radio-checked .ant-radio-inner {
  background-color: white !important;
  border-color: #f77e0b !important;
}
.ant-radio-checked .ant-radio-inner::after {
  border-color: white !important;
}
.ant-radio-checked:after {
  border: none !important;
}
.email-text {
  font-family: Poppins, sans-serif;

  height: 19px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #111828;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.signIn-text {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: normal;
  color: #111828;
  flex: none;
  order: 0;
  flex-grow: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.signUp-Button:hover {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  gap: 10px;
  background-color: white;
  width: 118px;
  height: 51px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  color: #263238;
  border: 2px solid #f77e0b;
}
.signIn-Button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  gap: 10px;
  background-color: white;
  width: 118px;
  height: 51px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  color: #263238;
  border: 2px solid white;
}
.signIn-Button:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  gap: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  border: 2px solid white;

  color: #ffffff;
  width: 118px;
  height: 51px;

  background: #f77e0b;
}

.signUp-Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  gap: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
  border: 2px solid white;

  color: #ffffff;
  width: 118px;
  height: 51px;

  background: #f77e0b;
}
.success-sub-text {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 158.5%;

  text-align: center;

  color: #111828;

  flex: none;
  order: 1;
  flex-grow: 0;
}
@media (max-width: 570px) {
  .appstore {
    justify-content: center !important;
  }
  .googleplay {
    justify-content: center !important;
  }
}
.phone-login {
  font-weight: bold;
}
.react-tel-input .selected-flag {
  width: 60px !important;
  padding: 0px 0 0 17px !important;
}
.contact_field {
  padding: 11px 14px 11px 72px !important;
}

// ===========User-Profile=============

.profile-image {
  width: 120px;
  height: 120px;
  margin-top: 32px;
}
.profile-heading {
  color: var(--grey-100, #111828);
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
}
.profile-address {
  color: #f77e0b;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-top: 6px;
}
.profile-username {
  color: #111828;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-top: 16px;
}
.profile-pictures {
  width: 100%;
  height: auto;
  border-radius: 12px;
  padding: 5px 0px 15px 15px;
  margin: 15px 0px 15px 0px;
}
.profile-intro {
  width: 100%;
  height: 300px;
  border-radius: 12px;
  padding: 5px 0px 15px 15px;
  margin: 15px 0px 15px 0px;
}

.profile-video {
  width: 300px;
  height: 400px;
  border-radius: 12px;
  margin: 15px 0px 15px 0px;
}
.profile-subheading {
  color: var(--grey-100, #111828);
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
}
.pic-image {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  margin-top: 10px;
}
.skill-skill {
  color: var(--white, #fff);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border-radius: 30px;
  background: #8abb2a;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  // margin-top: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

@media (max-width: 330px) {
  .profile-pictures {
    width: 280px !important;
  }
  .profile-intro {
    width: 280px !important;
  }
  .profile-video {
    width: 250px !important;
  }
}

// ===========Tradsman-Profile=============

.trad-details {
  width: 160px;
  height: auto;
}
.trad-username {
  color: #111828;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.trad-location {
  color: var(--primary-color, #f77e0b);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
  cursor: pointer;
}
.trad-banner {
  color: var(--white, #fff);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  border-radius: 30px;
  background: #f77e0b;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  width: 90px;
}
.trad-intro {
  width: 49%;
  min-height: 140px;
  height: auto;
  font-family: Poppins, sans-serif;
  font-weight: 400;
}
.trad-intro-text {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.21px;
}

@media (max-width: 768px) {
  .trad-intro {
    width: 100% !important;
  }
}
@media (max-width: 576px) {
  .trad-intro {
    width: 100% !important;
  }
}
.dropdown-text {
  color: var(--grey-100, #111828);
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

//===============Update profile =============================

.ant-steps-item-icon {
  border: 4px solid #f77e0b !important;
  width: 40px !important;
  height: 40px !important;
  background-color: white !important;
  margin-inline-end: 0px !important;
}

.ant-steps-item-icon > .ant-steps-icon {
  color: #f77e0b !important;
  font-weight: bold !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #f77e0b !important ;
  height: 12px !important;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #d2d2d2 !important;
  height: 12px !important;
}
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #d2d2d2 !important;
  height: 12px !important;
}
.ant-steps-item-title {
  padding-inline-end: 0px !important;
}
.ant-steps-item {
  padding-inline-start: 0px !important;
}

@media (max-width: 576px) {
  .ant-steps-item-icon {
    border: 2px solid #f77e0b !important;
    width: 30px !important;
    height: 30px !important;
    background-color: white !important;
    margin-top: 4px !important;
  }

  .ant-steps-item-icon > .ant-steps-icon {
    color: #f77e0b !important;
    font-weight: bold !important;
    top: -2px !important;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #f77e0b !important ;
    height: 9px !important;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #d2d2d2 !important;
    height: 9px !important;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #d2d2d2 !important;
    height: 9px !important;
  }
  .ant-steps-item-title {
    padding-inline-end: 0px !important;
  }
  .ant-steps-item {
    padding-inline-start: 0px !important;
  }
}

//===========vedio card==============

.profile-info {
  position: absolute;
  // top:75%;
  bottom: 5%;
  color: white;
  left: 20px;
  font-family: Poppins, sans-serif;
}
.badas {
  position: absolute;
  top: 25%;
  color: white;
  left: 35px;
}
.profile-info-heading {
  font-weight: 600;
}
.video-card {
  position: relative;
  width: 300px;
  height: 400px;
  // cursor: pointer;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: orange;
}
.video-banner {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 30px;
  left: 60px;
  color: var(--white, #fff);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  border-radius: 30px;
  background: #f77e0b;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  width: 80px;
}
.type-banner {
  transform: translate(-50%, -50%);
  color: var(--white, #fff);
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 600;
  border-radius: 30px;
  background: #f77e0b;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  width: 80px;
  margin-left: 80px;
}

.icon-div {
  position: absolute;
  top: 30px;
  right: 10px;
}
.icon-div1 {
  position: absolute;
  top: 212px;
  right: 10px;
}

.user-circle {
  width: 40px;
  height: 40px;
  border: 3px solid white;
  border-radius: 50%;
}

//=============gigs================================

//=============welcome=============

.welcome-text {
  font-family: Poppins, sans-serif;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: normal;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.img-fluid {
  width: 100% !important;
  height: 100% !important;
}

.ant-upload-select {
  width: 100% !important;
}
.checbox-c > .ant-divider-horizontal {
  margin: 15px !important;
}

//==============imageUploading================================

.imageUpload > input {
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
}
.imageUpload > labelP {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.preview {
  position: absolute;
  width: 100%;
  height: 75%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 2px solid #f77e0b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.proofPreview {
  position: absolute;
  width: 100%;
  height: 90%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 2px solid #f77e0b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.preview2 {
  position: absolute;
  width: 100%;
  height: 85%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.image-preview {
  position: absolute;
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 2px solid #f77e0b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 25px;
  height: 25px;
  top: -5px;
  right: -5px;
  border: 2px solid #f77e0b;
  cursor: pointer;
  font-size: 15px;
  color: #f77e0b;
  background-color: white;
  border-radius: 50%;
}
.imageUpload {
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
.imageUpload-input {
  position: absolute;
  width: 100px;
}
.preview--fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.LoadMore {
  color: orange;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.card-skills {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 3px;
  width: 220px;
}
.preview1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.image-preview1 {
  position: absolute;
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 2px solid #f77e0b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.gig-circle > input {
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
}

.gig-circle > labelP {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.review-text {
  color: #000;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 150%; /* 21px */
  letter-spacing: 0.21px;
}
.review-profile {
  width: 40px;
  height: 40px;
}

.review-profile-name {
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: 0.21px;
}
.review-content {
  color: #5f6368;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: 0.18px;
  display: flex;
  flex-wrap: wrap;
}

.ViewMore {
  color: var(--primary-color, #f77e0b);
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

a:hover {
  color: #f77e0b !important; /* Color of the text when hovered */
}

a {
  color: #f77e0b !important;
}

.link > a:hover {
  color: black !important;
}
.link > a {
  color: black !important;
}

.ant-select-selector {
  width: 100% !important;
  height: 56px !important;
  background-color: rgb(230, 231, 235) !important;
  margin-top: 7px !important;
}
.ant-select-arrow {
  color: black !important;
  font-size: 20px !important;
}
.ant-select-selection-search-input {
  height: 56px !important;
}
.ant-select-selection-item {
  line-height: 56px !important;
}

.signIn-text1 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: normal;
  color: #111828;
  flex: none;
  order: 0;
  flex-grow: 0;
}
@media (max-width: 768px) {
  .signIn-text1 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px !important;
    line-height: normal;
    color: #111828;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
@media (max-width: 1000px) {
  .signIn-text1 {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    line-height: normal;
    color: #111828;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
@media (max-width: 540px) {
  .signIn-text1 {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 16px !important;
    line-height: normal;
    color: #111828;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .profile-username {
    text-align: center !important;
  }
}

.opt-height {
  height: 100vh;
  background-color: #f77e0b;
}
@media (max-height: 660px) {
  .opt-height {
    height: 760px !important;
    background-color: #f77e0b;
  }
}

.welcome-background {
  background-image: url(../images/Layers.png);
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(141, 141, 141, 0.05);
  backdrop-filter: blur(45.92533493041992px);
}

.welcome1-background {
  background-image: url(../images/reactangle09.png);
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
}
.signIn-div {
  width: 80%;
  border-radius: 15px;
}
.social-text {
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.Limit-by {
  color: var(--grey-100, #111828);
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.ant-select-selection-placeholder {
  padding-top: 12px !important;
}

.ant-switch.ant-switch-checked:hover {
  background-color: #f77e0b !important;
}
.ant-switch.ant-switch-checked {
  background-color: #f77e0b !important;
}
.modal-div > .ant-modal-content {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.search-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.welcome-box {
  border-radius: 15px;
  width: 500px;
  padding: 30px;
}
.signIn-box {
  border-radius: 15px;
  padding: 30px;
  margin-top: 80px;
}
.signUp-box {
  border-radius: 15px;
  padding: 30px;
  margin-top: 100px;
}

@media (max-width: 1100px) {
  .welcome-box {
    width: 400px !important;
    padding: 20px !important;
  }
  .signIn-box {
    padding: 20px !important;
  }
}

@media (max-width: 700px) {
  .banner-height {
    height: 200px !important;
  }
  .welcome-box {
    width: 300px !important;
    padding: 20px !important;
  }
  .signIn-box {
    padding: 15px !important;
    margin-top: 20px !important;
    width: 90% !important;
  }
  .form-padding-div {
    padding: 29px 10px 12px 10px !important;
  }
  .canvasWidth {
    width: 300px !important;
  }
  .imageViewPic {
    width: 500px !important;
    height: 500px !important;
  }
  .next-image {
    margin-left: 90% !important;
  }
}
@media (max-width: 550px) {
  .imageViewPic {
    width: 400px !important;
    height: 400px !important;
  }
  .next-image {
    margin-left: 88% !important;
  }
}
@media (max-width: 430px) {
  .imageViewPic {
    width: 290px !important;
    height: 300px !important;
  }
  .next-image {
    margin-left: 84% !important;
  }
  .stories-view {
    width: 280px !important;
    height: 500px !important;
  }
  .deleteIcon {
    margin-left: 220px !important;
  }
  .modal-div {
    width: 300px;
  }
  .imageUpload {
    height: 75% !important;
  }
  .div-image-col {
    height: 150px !important;
  }
  .div-proof-col {
    height: 120px !important;
  }
  .div-proof-image-col {
    height: 300px !important;
  }
  .profile-details-div {
    width: 300px !important;
  }
  .trad-username {
    font-size: 11px !important;
  }
}

.view > .row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.form-padding-div {
  padding: 29px 27px 12px 27px;
}
.filter-icon {
  width: 58px;
  height: 48px;
}
.canvasWidth {
  width: 400px;
}
.imageViewPic {
  width: 600px;
  height: 600px;
}
.image-view-modal > *.ant-modal-content {
  padding: 10px !important;
}
.next-image {
  position: absolute;
  width: 50px;
  height: 50px;
  margin-top: 43%;
  margin-left: 92%;
  background-color: rgb(247, 126, 11);
  border-radius: 50%;
}
.prev-image {
  position: absolute;
  width: 50px;
  height: 50px;
  margin-top: 43%;
  margin-left: 0%;
  background-color: rgb(247, 126, 11);
  border-radius: 50%;
}
.profile-details-div {
  width: 350px;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
}

.stories-view {
  width: 400px;
  height: 660px;
  background-color: black;
}
.deleteIcon {
  position: absolute;
  margin-left: 360px;
  cursor: pointer;
  z-index: 2000;
  top: 100px;
}
.modal-div {
  width: 400px;
}

.div-image-col {
  height: 200px;
}

.div-proof-col {
  height: 148px;
}

.div-proof-image-col {
  height: 500px !important;
}

.banner-height {
  height: 320px;
  background-color: #f5f5f5;
  border-radius: 20px;
  cursor: pointer;
}
.storyLoading {
  height: 660px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.helloClass {
  opacity: 5;
}
.gigz-listing {
  width: 500px;
  height: 100vh;
  border-right: 2px solid grey;
}
.stories-circle {
  width: 70px;
  height: 70px;
  position: relative;
  border: 3px solid #f77e0b;
  border-radius: 50%;
}
.stories-circle > input {
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
}

.stories-circle > labelP {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.playstoreIcon {
  width: 200px;
}
@media (max-width: 430px) {
  .playstoreIcon {
    width: 180px;
  }
}
@media (max-width: 400px) {
  .playstoreIcon {
    width: 160px;
  }
}

@media (max-width: 355px) {
  .playstoreIcon {
    width: 145px;
  }
}
@media (max-width: 330px) {
  .playstoreIcon {
    width: 130px;
  }
}
.help_section {
  display: flex;
  padding-top: 30px;
}
.help_section_icon {
  margin-right: 10px;
}
.chat-button {
  border: 3px solid #f77e0b;
  border-radius: 10px;
  width: 100%;
  height: 60px;
  margin-top: 15px;
  font-family: Poppins, sans-serif;
  color: #f77e0b;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contract-button {
  border: 3px solid #f77e0b;
  border-radius: 10px;
  width: 100%;
  height: 60px;
  margin-top: 15px;
  font-family: Poppins, sans-serif;
  background-color: #f77e0b;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.report-profile {
  border: 1px solid rgb(210, 208, 208);
  border-radius: 10px;
  width: 100%;
  height: 60px;
  margin-top: 15px;
  font-family: Poppins, sans-serif;
  color: red;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reason-text {
  font-family: Poppins, sans-serif;
  font-size: 15px;
  text-align: center;
}
.ant-btn-default:hover,
.ant-btn-default:focus {
  border-color: #f77e0b !important;
  color: white !important;
}
.ant-input-affix-wrapper {
  background-color: #e6e7eb !important;
}
.ant-btn-default:hover,
.ant-btn-default:focus:disabled {
  border-color: #e6e7eb !important;
  color: #e6e7eb !important;
}
.cursor-pointer {
  cursor: pointer;
}

.checkmark {
  width: 30px;
}
